import React from "react";
import {Box, Flex, Icon, Stack, Text, useColorModeValue,    Link as LinkChakra} from "@chakra-ui/react";
import {ChevronRightIcon} from "@chakra-ui/icons";

export const DesktopSubNav = ({ label, href, subLabel,listSubNav, isFooter }) => {
    const hasListSubNav = listSubNav != null
  return (<React.Fragment>
          <LinkChakra
      href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('blue.50', 'gray.900') }}>
      <Stack direction={'row'} align={'center'}>
        <Box color={isFooter?"text.200":"text.500"}>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'blue.400' }}
            fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>

        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'blue.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </LinkChakra>
           {hasListSubNav && listSubNav.map((subnav) =>  <ItemSubNav subnav={subnav} />
            )}
    </React.Fragment>
  );
};

export const ItemSubNav = ({subnav})=>{
    const {href,label} = subnav
    return <LinkChakra
      href={href}
      m={0}
      role={'group'}
      display={'block'}
      p={1}
      pl={3}
      rounded={'md'}
      style={{marginTop:0}}
      _hover={{ bg: useColorModeValue('blue.50', 'gray.900') }}>
        <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'blue.400' }}
            fontWeight={400}>
            #{label}
          </Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'blue.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </LinkChakra>
}
