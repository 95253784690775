import React from "react"
import {
    Box, Container,
    Flex,
    useColorModeValue,
    useMediaQuery
} from "@chakra-ui/react";
import {HamburgerIcon} from "@chakra-ui/icons";
import {CONTENT_NAV} from "./data";
import {DesktopSubNav} from "./components";
const Footer = ({  }) => {

    return <Box sx={{width:'100%', bg:'bg.700',minHeight:'100px',p:'32px'}}><Container maxW="container.xl" sx={{
                margin: `10px auto`,
                pl:['2px','10px','10px'],
                pr:['2px','10px','10px'],
            }}><Flex sx={{width:'100%', flexWrap:"wrap", justifyContent:"space-around"}}>
        <Box sx={{color:'text.100',textAlign:'center'}}>2022 - La Voix des Elections <br/>Tous droits réservés</Box>
        <Box>
                  {CONTENT_NAV.map((child) => (
                    <DesktopSubNav key={child.label} {...child} isFooter={true}/>
                  ))}
        </Box>
    </Flex></Container>
        </Box>

}
export default Footer
