import PropTypes from "prop-types"
import React from "react"
import { motion } from "framer-motion"
import {SColorBG, SHeightMenuFull, SHeightMenuLow} from "../../gatsby-plugin-theme-ui-old";
import {Link} from "gatsby";
import {
    Box,
    Heading,
    Flex,
    Stack,
    useColorModeValue,
    Container,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverCloseButton,
    useMediaQuery
} from "@chakra-ui/react";


import {HamburgerIcon,
} from '@chakra-ui/icons';
import {CONTENT_NAV} from "./data";
import {DesktopSubNav} from "./components";

const MotionBox = motion(Box)
const MotionFlex = motion(Flex)
const MotionHeading = motion(Heading)



const Header = ({ isScroll }) => {

    const [isLowerThan580] = useMediaQuery("(max-width: 580px)")

  const popoverContentBgColor = useColorModeValue('white', 'gray.800');

   return <header> <MotionBox
        sx={{
            position:'fixed',
            width:'100%',
            zIndex:100,
            marginBottom: `1.45rem`,
            borderBottomStyle:"solid", borderBottomWidth:'1px'
        }}
        animate={isScroll?"low":"expand"}
        initial={"expand"}
        variants={{
                    low:{backgroundColor:`rgba(${SColorBG["rgb"][700]},0.99)`,height:SHeightMenuLow,borderBottomColor:SColorBG[900]},
                    expand:{backgroundColor:`rgba(${SColorBG["rgb"][500]},0.9)`,height:SHeightMenuFull,
                        borderBottomColor:SColorBG[500]}
        }}
    >
       <Container  maxW="container.xl" height={"100%"}>


       <MotionFlex width={"100%"} justifyContent={"space-between"}  height={"100%"} alignItems={"center"} sx={{position:'relative'}}>
        <Box></Box>

        <MotionFlex
            sx={{
                height:'100%',
                justifyContent:'center',
                display:'flex',
                flexDirection:'column'
            }}
        >
            <Link  sx={{"textDecoration": "none"
                          }}
                        to={ `/` }>
            <MotionHeading as={"h1"}
                variants={{
                    low:{scale:0.75},
                    expand:{scale:1}
        }}
                sx={{textAlign:'center',margin: 0, fontFamily:'heading',fontWeight:'bold',color:'text.300',
                    display:'flex', flexDirection:'column',
                    fontSize:['22px','30px','36px'],
                    justifyContent:'center'}}>
                    La Voix des élections
            </MotionHeading> </Link>

        </MotionFlex>
        <Box key={"menu"} >
             <Popover trigger={isLowerThan580?"click":"hover"} placement={"bottom-end"}>
          <PopoverTrigger>
            <HamburgerIcon cursor={"pointer"} w={7} h={7}
                           color={'text.100'}
                           variants={{
                            low:{scale:0.75},
                            expand:{scale:1}
                }} />
          </PopoverTrigger>
   <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}>
       {isLowerThan580 && <PopoverCloseButton />}
                <Stack>
                  {CONTENT_NAV.map((child) => (
                    <DesktopSubNav key={child.label} {...child} isFooter={false}/>
                  ))}
                </Stack>
              </PopoverContent>
</Popover>
        </Box>
       </MotionFlex>
       </Container>
    </MotionBox>
    </header>
}


Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
