export const CONTENT_NAV = [
    {
        label: "La Voix des élections",
        subLabel: "l'actualité récente",
        href: '/#actualite',
      },
    /*{
        label: 'Les élections',
        subLabel: '',
        listSubNav:[
            {"label":"Elections présidentielles 2022",  href:"/presidentielle-2022"},
            {"label":"Primaire gauche 2022",  href:"/primaire-gauche-2022"},
        ],
        href: '/elections',
      },*/
    {
        label: 'Les personnalités politiques',
        subLabel: 'Contenu sur les personnalités engagées dans les élections',
        href: '/#personnalite',
      },
      {
        label: 'Les partis politiques',
        subLabel: 'Contenu sur les partis politiques',
        href: '/parti-politique',
      },{
        label: 'Les analyses',
        subLabel: 'Des petites analyses pour comprendre',
        href: '/analyses',
      },
      {
        label: 'Le Projet',
        subLabel: 'En savoir plus sur ce projet',
        href: '/le-projet',
      },
      {
        label: 'La méthodologie',
        subLabel: 'Comprendre la construction des résultats',
        href: '/methodologie',
      }
      ]
